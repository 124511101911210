import apiRequest from "../../utils/apiRequest";
import {toast} from "react-toastify";
import {cleanData} from "../../utils/clean-data";
import additionalData from "../../components/LeadProfile/AdditionalData";
import {api} from "../config";

const autoAssignLeadsApi = async () => {
    try {
        const response = await apiRequest("auto_assign", "post");
        toast.success(response?.data?.message, { position: "bottom-right", theme: "dark" });
        return response.data;
    } catch (error) {
        console.error('Error in Auto Assign Leads:', error);
        toast.error(error?.response?.data?.message, { position: "bottom-right", theme: "dark" });
        throw error;
    }
};

// Reusable function to fetch lead data
const fetchLeadData = async (endpoint) => {
    return await apiRequest(endpoint, "get");
};

const getAllLeadsChartApi = async () => {
    return fetchLeadData("leadsFilterDate");
};

const getAllLeadsStatusApi = async () => {
    return fetchLeadData("leadsFilterStatus");
};

const getAllLeadsSourcesApi = async () => {
    return fetchLeadData("leadsFilterSource");
};

const getAllCompletedLeadsApi = async () => {
    return fetchLeadData("leadsFilterCompleted");
};

const createLeadApi = async (leadData, adminFlag) => {
    const url = adminFlag ? 'admin/leads' : 'lead';
    return await apiRequest(url, 'post', leadData);
};

const createLeadAdminApi = async (leadData) => {
    return await apiRequest("admin/leads", "post", leadData);
};

const deleteSingleLeadApi = async (id) => {
    return await apiRequest(`lead/${id}`, "delete");
};

// Helper function for downloading files
const downloadFile = (data, filename) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

const exportLeadsApi = async (clientId) => {
    const endpoint = clientId ? "admin/leads/export" : "leads/export";
    try {
        const response = await (clientId
                ? api.post(endpoint, { client_id: clientId }, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                : api.get(endpoint, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
        );

        // Trigger the download
        downloadFile(response.data, 'leads.xlsx');
    } catch (error) {
        console.error('Error exporting data:', error);
        throw error;
    }
};

const filterLeadsApi = async (filterData, controller = null) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // If controller is provided, add the signal to the request config
    if (controller) {
        config.signal = controller.signal;
    }

    try {
        return await apiRequest("leadsFilter", "post", filterData, config);
    } catch (error) {
        // Handle error logging and rethrow if necessary
        if (error.name === 'AbortError') {
            console.warn("Request was aborted");
        } else {
            const errorMessage = error?.response?.data?.message || error.message || 'An error occurred while filtering leads.';
            console.error('Error sending data:', errorMessage);
            throw new Error(errorMessage);
        }
    }
};

const filterLeadsByStatusApi = async (status, controller = null, clientId, records = 10, currentPage = 1) => {
    const data = {
        status: status,
        page: currentPage,
        numberOfRows: records,
        client_id: clientId,
    };

    try {
        return await apiRequest("leadsstatusfilter", "post", data, {signal: controller.signal});
    } catch (error) {
        // Handle error logging and rethrow if necessary
        if (error.name === 'AbortError') {
            console.warn("Request was aborted");
        } else {
            const errorMessage = error?.response?.data?.message || error.message || 'An error occurred while filtering leads by status.';
            console.error('Error sending data:', errorMessage);
            throw new Error(errorMessage);
        }
    }
};

const getAllLeadsApi = async (clientId, records = 10, currentPage = 1) => {
    const url = clientId
        ? `admin/leads?client_id=${clientId}&per_page=${records}&current_page=${currentPage}`
        : `/lead?per_page=${records}&current_page=${currentPage}`;
    return await apiRequest(url, 'get');
};

const getLeadsCountDashboardApi = async (signal) => {
    const url = '/widget';
    return await apiRequest(url, 'get', null, {}, signal);
};

const getSingleLeadApi = async (id, flag) => {
    const url = flag === 'admin' ? `admin/leads/${id}` : `lead/${id}`;
    return await apiRequest(url, 'get');
};

const importLeads = async (data) => {
    try {
        return await apiRequest("leads/import", 'post', data, {
            'Content-Type': 'multipart/form-data',
        });
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

const unassignedLeadsApi = async (leadId) => {
    return await apiRequest(`assign_lead/${leadId}`, 'post', { assigned_id: "" });
};

const updateQuotationApi = async (quotationData, id) => {
    const parsedData = cleanData(quotationData);
    return await apiRequest(`lead/quotation/${id}`, 'post', parsedData);
};

const updateSingleLeadApi = async (leadId, leadData) => {
    // Clean the leadData by removing null, undefined, or empty values
    const cleanedData = cleanData(leadData);
    let formData;

    // Check if there is a file in the leadData
    if (cleanedData.quotation_offer || cleanedData.action_proven) {
        formData = new FormData();

        // Append each field from cleanedData to the FormData object
        for (const key in cleanedData) {
            if (Object.prototype.hasOwnProperty.call(cleanedData, key)) {
                formData.append(key, cleanedData[key]);
            }
        }
    }

    // Use apiRequest and pass 'post' method with appropriate data
    return await apiRequest(`lead/${leadId}?_method=put`, 'post', formData || cleanedData, {
        'Content-Type': formData ? 'multipart/form-data' : 'application/json',
    });
};

export default {
    createLeadApi,
    exportLeadsApi,
    filterLeadsApi,
    filterLeadsByStatusApi,
    getAllLeadsApi,
    getLeadsCountDashboardApi,
    getSingleLeadApi,
    importLeads,
    unassignedLeadsApi,
    updateQuotationApi,
    updateSingleLeadApi,
    createLeadAdminApi,
    deleteSingleLeadApi,
    getAllLeadsChartApi,
    getAllLeadsSourcesApi,
    getAllLeadsStatusApi,
    getAllCompletedLeadsApi,
    autoAssignLeadsApi
}